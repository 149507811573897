<template>
  <div class="event-page-content">
    <a-row
      class="d-flex align-items-center mb-4"
      style="margin: -30px; padding: 22px 30px; background: #fff"
    >
      <a-col :span="18">
        <a-skeleton :loading="!page" :paragraph="{ rows: 3 }" active>
          <h3 class="mb-0" v-if="page" style="font-size: 20px">{{ title }}</h3>
        </a-skeleton>
      </a-col>
      <a-col
        v-if="page"
        :span="6"
        class="text-right"
        style="display: flex; justify-content: flex-end"
      >
        <CustomButton
          size="large"
          @click="() => $router.go(-1)"
        >
          Cancel
        </CustomButton>
        <a-button
          v-if="$route.params.page !== 'new'"
          type="primary"
          class="ml-2"
          size="large"
          @click="savePage"
          :loading="loading"
        >
          Save
        </a-button>
        <a-button
          v-else
          type="primary"
          class="ml-2"
          size="large"
          @click="savePage"
          :loading="loading"
        >
          Create
        </a-button>
      </a-col>
    </a-row>
    <a-card v-if="page">
      <span class="c-title">Content:</span>
      <a-tabs type="card" @change="changeTab">
        <a-tab-pane v-for="lang in allLangs" :key="lang.id" :tab="lang.short.toUpperCase()" force-render>
          <a-form :form="forms[allLangs.indexOf(lang, 0)]">
            <a-row :gutter="20">
              <a-col :span="6">
                <a-form-item label="Name">
                  <a-input
                    placeholder="Enter Page name"
                    v-decorator="[
                      `${lang.id}`,
                      {
                        initialValue: page.content[indexLang(lang)].name,
                        rules: lang.rules
                      },
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item label="Status">
                  <a-tooltip
                    title="active"
                    placement="bottom"
                    v-if="page.status === 'active'"
                  >
                    <a-icon
                      @click="changeStatus"
                      type="bulb"
                      theme="twoTone"
                      two-tone-color="#31ba9a"
                      class="font-size-18 green-bulb"
                    />
                  </a-tooltip>
                  <a-tooltip
                    title="inactive"
                    placement="bottom"
                    v-else
                  >
                    <a-icon
                      @click="changeStatus"
                      type="bulb"
                      theme="twoTone"
                      two-tone-color="#ccc"
                      class="font-size-18 grey-bulb"
                    />
                  </a-tooltip>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="Link">
                  <div class="link" v-if="$route.params.page !== 'new'">
                    <a-tooltip title="Copy to clipboard" placement="bottom">
                      <a-icon type="copy" class="text-primary" @click="clipboardHandle(link)" style="cursor: pointer; font-size: 16px; padding: 2px 8px 0 0;" />
                    </a-tooltip>
                    <a target="_blank" :href="link">{{ link }}</a>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
            <quill-editor
              v-if="mode === 'visual'"
              v-model="page.content[indexLang(lang)].html"
              :options="quillEditorOptions"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onEditorChange($event, lang)"
            />
            <editor
              v-if="mode === 'html'"
              v-model="page.content[indexLang(lang)].html"
              :options="editorOptions"
              @init="editorInit"
              lang="html"
              theme="crimson_editor"
              class="editor"
            />
            <editor
              v-if="mode === 'style'"
              v-model="page.content[indexLang(lang)].style"
              :options="editorOptions"
              @init="editorInit"
              lang="css"
              theme="crimson_editor"
              class="editor"
            />
            <editor
              v-if="mode === 'script'"
              v-model="page.content[indexLang(lang)].script"
              :options="editorOptions"
              @init="editorInit"
              lang="javascript"
              theme="crimson_editor"
              class="editor"
            />
          </a-form>
        </a-tab-pane>
          <a-radio-group
            slot="tabBarExtraContent"
            button-style="solid"
            :default-value="mode"
            @change="changeMode"
          >
            <a-radio-button value="visual"> Visual </a-radio-button>
            <a-radio-button value="html"> HTML </a-radio-button>
            <a-radio-button value="style"> CSS </a-radio-button>
            <a-radio-button value="script"> JS </a-radio-button>
          </a-radio-group>
      </a-tabs>
    </a-card>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import editor from 'vue2-ace-editor'
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-vue'
import CustomButton from '@/components/custom/button/CustomButton'
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'

Quill.register({
  'modules/imageDrop': ImageDrop,
  'modules/imageResize': ImageResize,
})

const defaultPage = {
  status: 'active',
  content: [
    {
      lang_id: 1,
      name: 'name',
      html: 'html',
      style: 'style',
      script: 'script',
    },
  ],
}
/* eslint-disable */
export default {
  components: {
    editor,
    quillEditor,
    CustomButton,
  },
  data: () => ({
    mode: 'visual',
    quillContent: '',
    quillEditorOptions: {
      placeholder: 'Edit here...',
      modules: {
        imageDrop: true,
        imageResize: {
          modules: ['Resize', 'DisplaySize'],
        },
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ['clean'],

          ['link', 'video'],
        ],
      },
    },
    title: 'Новая страница',
    page: null,
    form: null,
    forms: [],
    validForms: [],
    link: null,
    langs_page: [],
    active_langs: [],
    active_lang: 0,
    size: 'large',
    widthSelect: 150,
    allLangs: [],
    editorOptions: {
      useWorker: false,
      showPrintMargin: false,
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      tabSize: 2,
      wrap: true,
    },
    loading: false,
    languages: ['ru', 'kz', 'en', 'uz'],
  }),
  computed: {
    ...mapState(['langs']),
    dynamicStyleObject: function() {
      return {
        'display': 'inline-flex',
        'min-width': this.widthSelect + 'px',
      }
    },
    currentIndex() {
      if (!this.page.id) {
        return this.event.pages + 1
      }
      return this.page.index
    },
    availableIndexes: {
      get() {
        if (!this.page.id) {
          return this.event.pages_indexes
        }
        return this.page.indexes
      },
      set(value) {
        this.page.index = value
      },
    },
    model: {
      get() {
        return ''
      },
      set(newValue) {
        return newValue
      },
    },
    ...mapFields('event', {
      event: 'event',
    }),
  },
  async created() {
    const _this = this
    try {
      await _this.fetchEvent(_this.$route.params.event)
      if (_this.$route.params.page !== 'new') {
        await this.fetchPage(_this.$route.params.page)
        this.title = this.page.content[this.indexLang(this.allLangs[0])].name
      } else {
        _this.page = { ...defaultPage }
        _this.page.index = this.currentIndex
      }
      this.allLangs.forEach((lang, index) => {
        this.allLangs[index].rules = [
          { required: true, message: `${lang.name} page name is required!` },
          { min: 4, message: 'At least 4 characters' },
          { max: 255, message: 'Maximum 255 characters' },
        ]
        this.forms.push(
          _this.$form.createForm(this, {
            name: `page${index}`,
          }),
        )
      })
      this.link = `${this.event.link}/${this.event.default_language.short}/${this.page.index}`
    } catch (e) {
      _this.$notification.error({
        message: 'Error',
        description: 'Ошибка при загрузке страницы',
      })
    }
  },
  methods: {
    clipboardHandle(v) {
      this.$clipboard(v)
    },
    indexLang(lang) {
      const idx = this.page.content.findIndex(item => {
        return lang.id === item.lang_id
      })
      return idx
    },
    changeStatus() {
      this.page.status = (this.page.status === 'active') ? this.page.status = 'inactive' : this.page.status = 'active'
    },
    changeMode(e) {
      const mode = e.target.value
      this.mode = mode
      if (mode === 'html') {
        this.page.content[this.active_lang].html = this.beautifyHtml(this.page.content[this.active_lang].html)
      }
    },
    onEditorBlur() {},
    onEditorFocus() {},
    onEditorReady() {},
    onEditorChange({ quill, html, text }, lang) {
    },
    beautifyHtml(html) {
      if (html) {
        const beautyHtml = require('js-beautify').html
        const array = html.split(/\n/)
        array[0] = array[0].trim()
        html = array.join('\n')
        return beautyHtml(html)
      }
    },

    handleSelectIndex(value) {
      this.page.index = value
    },
    editorInit: function () {
      require('brace/ext/language_tools')
      require('brace/mode/html')
      require('brace/mode/css')
      require('brace/mode/javascript')
      require('brace/theme/crimson_editor')
      require('brace/ext/beautify')
    },
    async savePage(e) {
      e.preventDefault()
      this.validForms = []
      this.forms.forEach((form, index) => {
        form.validateFields(async (err, values) => {
          if (!err) {
            this.validForms.push('valid form')
            this.page.content.forEach((el) => {
              for (const [key, value] of Object.entries(values)) {
                if (el.lang_id == key) {
                  return el.name = value
                }
              }
            })
            if (this.validForms.length === this.allLangs.length) {
              if (!this.page.id) {
                await this.createPage({
                  status: this.page.status,
                  content: this.page.content,
                })
                return false
              }
              await this.editPage({
                status: this.page.status,
                content: this.page.content,
              })
            }
          } else {
            const errLang = this.allLangs.find((el, idx) => idx === index)
            this.$notification.error({
              message: 'Error',
              description: errLang.rules[0].message,
            })
          }
        })
      })
    },
    async createPage(payload) {
      this.validForms = []
      try {
        this.loading = true
        await apiClient.post(`admin/events/${this.event.id}/pages/store`, {
          ...payload,
        })
        this.$notification.success({
          message: 'Success',
          description: 'Page created successfully',
          duration: 2,
        })
        this.$router.back()
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        })
      } finally {
        this.loading = false
      }
    },
    async editPage(payload) {
      this.validForms = []
      try {
        this.loading = true
        const {
          data,
        } = await apiClient.patch(
          `admin/events/${this.event.id}/pages/${this.page.id}`,
          { ...payload },
        )
        this.page = data.data
        this.$notification.success({
          message: 'Success',
          description: 'Page edited successfully',
          duration: 2,
        })
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        })
      } finally {
        this.loading = false
      }
    },
    changeTab(key) {
      this.active_lang = this.page.content.findIndex((item, index) => {
        return item.lang_id === key
      })
      const url_lang = this.allLangs.find((item) => {
        return item.id === key
      })
      this.link = `${this.event.link}/${url_lang.short}/${this.page.index}`
    },
    fetchEvent(eventID) {
      const _this = this
      return new Promise((resolve, reject) => {
        try {
          apiClient
            .get(`admin/events/${eventID}`)
            .then(({ data }) => {
              _this.event = data.data
              _this.event.langs.forEach((el, index) => {
                if (el.id === _this.event.default_lang_id) {
                  _this.event.langs.splice(index, 1)
                }
              })
              _this.allLangs = [_this.event.default_language, ..._this.event.langs]
              defaultPage.content = []
              _this.allLangs.forEach((item) => {
                defaultPage.content.push({
                  lang_id: item.id,
                  name: '',
                  html: '',
                  style: '',
                  script: '',
                })
              })
              defaultPage.content[0].lang_id = _this.event.default_lang_id
              resolve(data.data)
            })
            .catch((error) => {
              reject(error)
            })
        } catch (e) {
          reject(e)
        }
      })
    },
    fetchPage(pageID) {
      const _this = this
      return new Promise((resolve, reject) => {
        try {
          apiClient
            .get(`admin/events/${_this.event.id}/pages/${pageID}`)
            .then(({ data }) => {
              _this.page = data.data
              if (_this.page.content.length < this.allLangs.length) {
                const arrNewLangs = [...this.allLangs]
                _this.page.content.forEach((el, index) => {
                  arrNewLangs.forEach((item, idx) => {
                    if (el.lang_id === item.id) {
                      arrNewLangs.splice(idx, 1)
                    }
                  })
                })
                arrNewLangs.forEach(el => {
                  this.page.content.push({
                    html: '',
                    name: '',
                    script: '',
                    style: '',
                    lang_id: el.id,
                  })
                })
              }
              // Получаем контент языка по умолчанию
              this.active_lang = this.page.content.findIndex((item) => {
                return item.lang_id === this.allLangs[0].id
              })
              resolve(data.data)
            })
            .catch((error) => {
              reject(error)
            })
        } catch (e) {
          reject(e)
        }
      })
    },
  },
}
</script>

<style lang="scss">
.event-page-content {
  .link-page {
    font-size: 14px;
    margin-bottom: 32px;
    display: block;
  }
  .ant-tabs-tab {
    margin-right: 2px !important;
  }
  .c-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 7.5px;
    display: block;
  }
  .editor {
    height: 310px !important;
  }
  .ql-container.ql-snow {
    height: auto;
  }
  .ql-editor {
      height: 40vh;
      overflow-y: scroll;
  }
}
.icon-add-lang {
  padding: 10px 15px;
  border: 1px solid #e4e9ef;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
  &:hover {
    border-color: #45baf8;
    transition: all .3s ease;
    & svg {
      transition: all .3s ease;
      & path {
        transition: all .3s ease;
        fill: #45baf8;
      }
    }
  }
}
.active-icon {
  border-color: #308dbf;
  & svg {
    transform: rotate(-45deg) scale(1.35);
    transition: all .3s ease;
    & path {
      transition: all .3s ease;
      fill: #308dbf;
    }
  }
}
.add-languages {
  display: flex;
  margin-bottom: 10px;
  & > i {
    margin-right: 10px;
  }
}
.ant-select-dropdown {
  width: 150px !important;
}
</style>
